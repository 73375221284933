(function() {
    'use strict';

    angular.module('ionicOffline', [
        'ionic',
        'ngCordova',
        'ngProgress',
        'ionicAlert',
        'ionicRequest',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabFileManagement',
        'uabLocalStorage',
        'uabMoment',
        'uabPromiseManagement',
        'uabRegistry'
    ]);
})();
(function() {
    'use strict';

    localforage.ready(function() {
        console.log(
            'Offline database ready.',
            localforage.driver()
        );
    });

    localforage.config({
        name: 'uabOffline',
        driver: [
            localforage.INDEXEDDB,
            localforage.WEBSQL,
            localforage.localforage
        ],
        size: 200*1024*1024
    });
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'LocalForageService',
        LocalForageService
    );

    LocalForageService.$inject = [
        'DefaultVariableService',
        'PromiseManagementService'
    ];

    function LocalForageService(
        DefaultVariableService,
        PromiseManagementService
    ) {
        var LocalForageService = this;

        var KEY_SEPARATOR = '---';

        LocalForageService.executeRequest = executeRequest;
        function executeRequest(callback, args) {
            if (!DefaultVariableService.isArray(args)) {
                args = [args];
            }

            if (LocalForageService.isExecuting) {
                LocalForageService.isExecuting = LocalForageService.isExecuting.then(
                    function() {
                        var promise = false;
                        switch(args.length) {
                            case 0:
                                promise = localforage[callback]();
                                break;
                            case 1:
                                promise = localforage[callback](args[0]);
                                break;
                            case 2:
                                promise = localforage[callback](args[0], args[1]);
                                break;
                            case 3:
                                promise = localforage[callback](args[0], args[1], args[2]);
                                break;
                        }

                        LocalForageService.isExecuting = PromiseManagementService.convertPromise(
                            promise
                        );

                        return LocalForageService.isExecuting.then(
                            function(a) {
                                return a;
                            },
                            function(b) {
                                return b;
                            }
                        );
                    }
                );

                return LocalForageService.isExecuting;
            } else {
                LocalForageService.isExecuting = false;
                switch(args.length) {
                    case 0:
                        LocalForageService.isExecuting = localforage[callback]();
                        break;
                    case 1:
                        LocalForageService.isExecuting = localforage[callback](args[0]);
                        break;
                    case 2:
                        LocalForageService.isExecuting = localforage[callback](args[0], args[1]);
                        break;
                    case 3:
                        LocalForageService.isExecuting = localforage[callback](args[0], args[1], args[2]);
                        break;
                }

                LocalForageService.isExecuting = PromiseManagementService.convertPromise(
                    LocalForageService.isExecuting
                ).then(
                    function(a) {
                        return a;
                    },
                    function(b) {
                        return b;
                    }
                ).finally(
                    function () {
                        LocalForageService.isExecuting = false;
                    }
                );

                return LocalForageService.isExecuting;
            }
        }

        LocalForageService.setItem = setItem;
        function setItem(model, item) {
            var id = DefaultVariableService.getInteger(
                item.id,
                0
            );

            var key = LocalForageService.getKey(model, id);

            return LocalForageService.executeRequest(
                'setItem',
                [
                    key,
                    item
                ]
            );
        }

        LocalForageService.clear = clear;
        function clear() {
            return LocalForageService.executeRequest(
                'clear'
            );
        }

        LocalForageService.exists = exists;
        function exists(key) {
            return LocalForageService.executeRequest(
                'getItem',
                [
                    key
                ]
            ).then(
                function(item) {
                    return item !== null;
                }
            );
        }

        LocalForageService.getAll = getAll;
        function getAll(model) {
            var key = LocalForageService.getKey(
                model
            );

            return LocalForageService.executeRequest(
                'startsWith',
                [
                    key
                ]
            ).then(
                function(results) {
                    var items = [];
                    for (var key in results) {
                        if (results.hasOwnProperty(key)) {
                            items.push(
                                results[key]
                            );
                        }
                    }

                    return items;
                }
            );
        }

        LocalForageService.getItem = getItem;
        function getItem(model, id) {
            var key = LocalForageService.getKey(model, id);

            return LocalForageService.executeRequest(
                'getItem',
                [
                    key
                ]
            ).then(
                function(item) {
                    if (item === null) {
                        return false;
                    }

                    return item;
                }
            );
        }

        LocalForageService.getKey = getKey;
        function getKey(model, id) {
            id = DefaultVariableService.get(
                id,
                0
            );

            if (model === 'settings') {
                return model;
            } else {
                var key = model + KEY_SEPARATOR;

                if (id !== 0) {
                    key += id;
                }

                return key;
            }
        }

        LocalForageService.getLength = getLength;
        function getLength() {
            return LocalForageService.executeRequest(
                'length'
            );
        }

        LocalForageService.removeItem = removeItem;
        function removeItem(model, id) {
            id = DefaultVariableService.get(
                id,
                0
            );

            LocalForageService.getKey(model, id);

            return LocalForageService.executeRequest(
                'removeItem',
                [
                    LocalForageService.getKey,
                    model,
                    id
                ]
            );
        }

        LocalForageService.isEmpty = isEmpty;
        function isEmpty() {
            return LocalForageService.getLength().then(
                function(length) {
                    return length === 0;
                }
            );
        }

        LocalForageService.removeAll = removeAll;
        function removeAll(keys) {
            return LocalForageService.executeRequest(
                'removeItems',
                [
                    keys
                ]
            );
        }

        LocalForageService.reset = reset;
        function reset() {

        }

        LocalForageService.init = init;
        function init() {
            LocalForageService.reset();
        }

        LocalForageService.init();

        return LocalForageService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineService',
        OfflineService
    );

    OfflineService.$inject = [
        '$cordovaNetwork',
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        'OfflineConnectivityService',
        'OfflineRequestService',
        'OfflineStorageService',
        'OfflineSyncService',
        'RequestManagementService'
    ];

    function OfflineService(
        $cordovaNetwork,
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        OfflineConnectivityService,
        OfflineRequestService,
        OfflineStorageService,
        OfflineSyncService,
        RequestManagementService
    ) {
        var OfflineService = this;

        var ACTION_ADD = 'add';
        var ACTION_DELETE = 'delete';

        var ACTION_EDIT = 'edit';
        var ACTION_UPDATE = 'update';

        var ACTION_GET = 'get';
        var ACTION_RECOMMENDED = 'recommended';

        var ACTION_LOGIN = 'login';
        var ACTION_LOGOUT = 'logout';

        OfflineService.clearAll = clearAll;
        function clearAll() {
            return OfflineStorageService.clearAll();
        }

        OfflineService.checkSync = checkSync;
        function checkSync() {
            return OfflineSyncService.checkSync();
        }

        OfflineService.getAll = getAll;
        function getAll(model) {
            return OfflineStorageService.getAll(model);
        }

        OfflineService.getCount = getCount;
        function getCount(model) {
            return OfflineStorageService.getCount(model);
        }

        OfflineService.getLastSynced = getLastSynced;
        function getLastSynced() {
            return OfflineSyncService.getLastSync();
        }

        OfflineService.getLength = getLength;
        function getLength() {
            return OfflineStorageService.length();
        }

        OfflineService.getOfflineRequests = getOfflineRequests;
        function getOfflineRequests() {
            return OfflineSyncService.getOfflineRequests();
        }

        OfflineService.getProgress = getProgress;
        function getProgress() {
            return OfflineSyncService.getProgress();
        }

        OfflineService.getVideoExtension = getVideoExtension;
        function getVideoExtension() {
            if (!DefaultVariableService.isDefined(OfflineService.videoExtension)) {
                OfflineService.videoExtension = EnvironmentService.get('offlineVideoExtension');
                if (!OfflineService.videoExtension) {
                    OfflineService.videoExtension = 'mp4';
                }
            }

            return OfflineService.videoExtension;
        }

        OfflineService.isMobile = isMobile;
        function isMobile() {
            return OfflineConnectivityService.isMobile();
        }

        OfflineService.isOffline = isOffline;
        function isOffline() {
            return !OfflineService.isOnline();
        }

        OfflineService.isOnline = isOnline;
        function isOnline() {
            var isOnline = OfflineConnectivityService.isOnline();

            if (isOnline) {
                OfflineSyncService.checkSync();
            }

            return isOnline;
        }

        OfflineService.isLoggedIn = isLoggedIn;
        function isLoggedIn() {
            return OfflineStorageService.isLoggedIn();
        }

        OfflineService.isSyncing = isSyncing;
        function isSyncing() {
            return OfflineSyncService.getIsSyncing();
        }

        OfflineService.register = register;
        function register(callback) {
            OfflineConnectivityService.register(callback);
        }

        OfflineService.remove = remove;
        function remove(model, id) {
            return OfflineStorageService.removeItem(model, id);
        }

        OfflineService.request = request;
        function request(request) {
            var action = RequestManagementService.getAction(request);

            var promise = false;
            switch(action) {
                case ACTION_ADD:
                    promise = OfflineStorageService.create(request);
                    break;
                case ACTION_DELETE:
                    promise = ErrorService.addError('Cannot delete while offline.');
                    break;
                case ACTION_EDIT:
                case ACTION_UPDATE:
                    promise = OfflineStorageService.edit(request);
                    break;
                case ACTION_GET:
                case ACTION_RECOMMENDED:
                    promise = OfflineRequestService.get(request);
                    break;
                case ACTION_LOGIN:
                case ACTION_LOGOUT:
                    promise = ErrorService.addError(
                        'Unsupported action (' + action + ') while offline.'
                    );
                    break;
                default:
                    promise = ErrorService.addWarning(
                        'Unsupported action (' + action + ') while offline.'
                    );
                    break;
            }

            return promise;
        }

        OfflineService.synchronize = synchronize;
        function synchronize(i) {
            return OfflineSyncService.sync(i);
        }

        OfflineService.reset = reset;
        function reset() {

        }

        OfflineService.init = init;
        function init() {
            OfflineService.reset();
        }

        OfflineService.init();

        return OfflineService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineConnectivityService',
        OfflineConnectivityService
    );

    OfflineConnectivityService.$inject = [
        '$cordovaNetwork',
        'RegistryService'
    ];

    function OfflineConnectivityService(
        $cordovaNetwork,
        RegistryService
    ) {
        var OfflineConnectivityService = this;

        var NETWORK_CONNECTIVITY = 'isOnline';

        OfflineConnectivityService.hadInternet = hadInternet;
        function hadInternet() {
            return OfflineConnectivityService.doesNotHaveInternet;
        }

        OfflineConnectivityService.hasInternet = hasInternet;
        function hasInternet() {
            return OfflineConnectivityService.currentlyHasInternet;
        }

        OfflineConnectivityService.isMobile = isMobile;
        function isMobile() {
            if (document.URL.startsWith('http')) {
                return false;
            } else if (typeof ionic !== 'undefined') {
                return ionic.Platform.isAndroid() || ionic.Platform.isIOS() || ionic.Platform.isIPad() || ionic.Platform.isWindowsPhone();
            }

            return false;
        }

        OfflineConnectivityService.isOffline = isOffline;
        function isOffline() {
            return !OfflineConnectivityService.isOnline();
        }

        OfflineConnectivityService.isOnline = isOnline;
        function isOnline() {
            OfflineConnectivityService.doesNotHaveInternet = OfflineConnectivityService.currentlyHasInternet;

            OfflineConnectivityService.currentlyHasInternet = navigator.onLine;
            if (OfflineConnectivityService.isMobile()) {
                OfflineConnectivityService.currentlyHasInternet = $cordovaNetwork.isOnline();
            }

            if (OfflineConnectivityService.currentlyHasInternet !== OfflineConnectivityService.doesNotHaveInternet) {
                RegistryService.notify(
                    NETWORK_CONNECTIVITY,
                    OfflineConnectivityService.currentlyHasInternet
                );
            }

            return OfflineConnectivityService.currentlyHasInternet;
        }

        OfflineConnectivityService.register = register;
        function register(callback) {
            RegistryService.register(NETWORK_CONNECTIVITY, callback);
        }

        OfflineConnectivityService.reset = reset;
        function reset() {
            OfflineConnectivityService.doesNotHaveInternet = true;

            OfflineConnectivityService.currentlyHasInternet = true;
        }

        OfflineConnectivityService.init = init;
        function init() {
            OfflineConnectivityService.reset();
        }

        OfflineConnectivityService.init();

        return OfflineConnectivityService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineDataService',
        OfflineDataService
    );

    OfflineDataService.$inject = [
        'DefaultVariableService',
        'MomentService',
        'OfflineStorageService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function OfflineDataService(
        DefaultVariableService,
        MomentService,
        OfflineStorageService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var OfflineDataService = this;

        OfflineDataService.filterByParam = filterByParam;
        function filterByParam(objects, param, strict) {
            strict = DefaultVariableService.get(
                strict,
                false
            );

            var value = RequestManagementService.getValueFromParam(param);

            if (value) {
                return objects.filter(
                    function(item) {
                        return OfflineDataService.shouldKeepItem(item, param, strict);
                    }
                );
            }

            return objects;
        }

        OfflineDataService.shouldKeepItem = shouldKeepItem;
        function shouldKeepItem(item, param, strict) {
            var returnVar = true;

            var key = RequestManagementService.getKeyFromParam(param);
            if (key !== 'join') {
                var value = RequestManagementService.getValueFromParam(param);

                var type = '=';
                if (param.indexOf('>') !== -1) {
                    type = '>';
                } else if (param.indexOf('<') !== -1) {
                    type = '<';
                }

                var itemValue = null;
                if (DefaultVariableService.isDefined(item[key])) {
                    itemValue = DefaultVariableService.get(
                        item[key],
                        null
                    );
                } else if (key.indexOf('.') !== -1) {
                    var indexOf = key.indexOf('.');

                    var childField = key.substring(0, indexOf);

                    var childParam = param;

                    indexOf = childParam.indexOf('.');
                    if (indexOf !== -1) {
                        childParam = childParam.substring(indexOf + 1);
                    }

                    if (!DefaultVariableService.isDefined(item[childField])) {
                        childField = DefaultVariableService.toUnderScore(childField);

                        if (!DefaultVariableService.isDefined(item[childField])) {
                            childField = childField + 's';
                        }
                    }

                    var child = DefaultVariableService.get(item[childField]);
                    if (DefaultVariableService.isArray(child)) {
                        returnVar = !strict;

                        var childLength = child.length;

                        for (var i = 0; i < childLength; i++) {
                            returnVar = OfflineDataService.shouldKeepItem(child[i], childParam, strict);

                            if (returnVar) {
                                return returnVar;
                            }
                        }

                        return returnVar;
                    } else if (DefaultVariableService.isObject(child)) {
                        return OfflineDataService.shouldKeepItem(child, childParam, strict);
                    }
                } else {
                    var joinData = DefaultVariableService.get(
                        item._joinData,
                        false
                    );

                    if (joinData && DefaultVariableService.isDefined(joinData[key])) {
                        itemValue = DefaultVariableService.getArray(
                            joinData[key]
                        );

                        if (itemValue) {
                            type = 'IN';
                        }
                    }
                }

                if (itemValue !== null) {
                    var isNumber = DefaultVariableService.isStringNumber(value);
                    isNumber &= DefaultVariableService.isNumber(itemValue) || DefaultVariableService.isArray(itemValue);

                    if (isNumber) {
                        value = DefaultVariableService.getInteger(
                            value
                        );
                    } else if (itemValue === true || itemValue === 'true') {
                        itemValue = '1';
                    } else if (itemValue === false || itemValue === 'false') {
                        itemValue = '0';
                    }

                    if (itemValue) {
                        switch (type) {
                            case 'IN':
                                returnVar = itemValue.indexOf(value) !== -1;
                                break;
                            case '<':
                                returnVar = itemValue <= value;
                                break;
                            case '>':
                                returnVar = itemValue >= value;
                                break;
                            default:
                                returnVar = itemValue === value;
                                break;
                        }
                    }
                } else if (strict) {
                    returnVar = false;
                }
            }

            return returnVar;
        }

        OfflineDataService.filterByParams = filterByParams;
        function filterByParams(objects, request, strict) {
            if (!DefaultVariableService.isBoolean(strict)) {
                var joinType = RequestManagementService.getJoin(request);

                if (joinType.toLowerCase() === 'inner') {
                    strict = true;
                } else {
                    strict = false;
                }
            }

            var params = RequestManagementService.getParams(request);

            var paramsLength = params.length;
            for (var i = 0; i < paramsLength; i++) {
                var param = params[i];

                if (param) {
                    var indexOf = param.indexOf('=');

                    if (indexOf !== -1) {
                        objects = OfflineDataService.filterByParam(objects, param, strict);
                    }
                }
            }

            return objects;
        }

        OfflineDataService.getWeeks = getWeeks;
        function getWeeks(activeUser) {
            var startTime = DefaultVariableService.get(
                activeUser.start_time,
                activeUser.created
            );

            startTime = MomentService(startTime);

            var endTime = MomentService();

            return endTime.diff(startTime, 'weeks') + 1;
        }

        OfflineDataService.filterRecommended = filterRecommended;
        function filterRecommended(objects) {
            var activeUser = OfflineStorageService.getActiveUser();

            return OfflineDataService.getUserTags(activeUser).then(
                function(userTags) {
                    var userWeek = OfflineDataService.getWeeks(activeUser);
                    userWeek = DefaultVariableService.getInteger(
                        userWeek,
                        1
                    );

                    return objects.filter(
                        function (item) {
                            var itemWeek = DefaultVariableService.getInteger(
                                item.week
                            );

                            if (itemWeek <= userWeek) {
                                var itemTags = DefaultVariableService.getArray(
                                    item.tags
                                );

                                var itemTagsLength = itemTags.length;

                                if (itemTagsLength === 0) {
                                    return false;
                                } else {
                                    var userTagsLength = userTags.length;
                                    for (var i = 0; i < itemTagsLength; i++) {
                                        var itemTag = itemTags[i];
                                        for (var u = 0; u < userTagsLength; u++) {
                                            var userTag = userTags[u];
                                            if (userTag.id === itemTag.id) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }

                            return false;
                        }.bind(OfflineDataService)
                    );
                }
            );
        }

        OfflineDataService.getUserTags = getUserTags;
        function getUserTags(activeUser) {
            return PromiseManagementService.convertPromise(
                localforage.startsWith('tags---').then(
                    function(tags) {
                        var returnVar = [];

                        if (tags) {
                            for (var property in tags) {
                                if (tags.hasOwnProperty(property)) {
                                    var tag = tags[property];

                                    var joinData = DefaultVariableService.get(
                                        tag._joinData,
                                        false
                                    );

                                    if (joinData) {
                                        var userIds = DefaultVariableService.get(
                                            joinData.user_id,
                                            false
                                        );

                                        if (DefaultVariableService.isArray(userIds)) {
                                            if (userIds.indexOf(activeUser.id) !== -1) {
                                                returnVar.push(tag);
                                            }
                                        } else if (userIds === activeUser.id) {
                                            returnVar.push(tag);
                                        }
                                    }
                                }
                            }
                        }

                        return returnVar;
                    }
                )
            );
        }

        OfflineDataService.joinManyToOneObjects = joinManyToOneObjects;
        function joinManyToOneObjects(object, model, id) {
            return OfflineStorageService.getItem(model, id).then(
                function(childObject) {
                    if (childObject) {
                        var modelLength = model.length;
                        var lastChar = model.substring(
                            modelLength - 1
                        );

                        if (lastChar === 's') {
                            model = model.substring(0, modelLength - 1);
                        }

                        object[model] = childObject;
                    }

                    return object;
                }
            );
        }

        OfflineDataService.joinOneToManyObjects = joinOneToManyObjects;
        function joinOneToManyObjects(object, request, oneToManyModel) {
            var parentModel = RequestManagementService.getModel(request);
            if (parentModel) {
                return OfflineStorageService.getAll(oneToManyModel).then(
                    function(objects) {
                        if (objects) {
                            var parentField = RequestManagementService.getModel(request);
                            if (parentField) {
                                var parentFieldLength = parentField.length;
                                var lastChar = parentField.substring(
                                    parentFieldLength - 1
                                );

                                if (lastChar === 's') {
                                    parentField = parentField.substring(0, parentFieldLength - 1);
                                }
                                parentField += '_id';

                                var param = parentField + '=' + object.id;

                                return OfflineDataService.filterByParam(objects, param, true);
                            }
                        }

                        return objects;
                    }
                ).then(
                    function(objects) {
                        var oneToManyField = DefaultVariableService.toUnderScore(
                            oneToManyModel
                        );

                        object[oneToManyField] = objects;

                        return object;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(object);
        }

        OfflineDataService.joinObject = joinObject;
        function joinObject(object, request) {
            var promises = [];

            var params = RequestManagementService.getParams(request);

            var paramsLength = params.length;
            for (var i = 0; i < paramsLength; i++) {
                var param = params[i];

                var parentModel = RequestManagementService.getModel(request);

                var sections = param.split('.');

                var sectionsLength = sections.length;
                for (var j = 0; j < sectionsLength; j++) {
                    var childModel = sections[j];

                    var indexOf = childModel.indexOf('=');
                    if (indexOf === -1) {
                        if (j !== 0) {
                            parentModel = DefaultVariableService.toUnderScore(
                                sections[j-1]
                            ).toLowerCase();
                        }

                        childModel = DefaultVariableService.toUnderScore(
                            childModel
                        ).toLowerCase();

                        var field = childModel;

                        var fieldLength = field.length;

                        var lastChar = field.substring(
                            fieldLength - 1
                        );

                        if (lastChar === 's') {
                            field = field.substring(0, fieldLength - 1);
                        }
                        field += '_id';

                        var promise = false;
                        if (object.hasOwnProperty(field)) {
                            var id = DefaultVariableService.get(
                                object[field],
                                false
                            );

                            if (id) {
                                promise = OfflineDataService.joinManyToOneObjects(object, childModel, id);
                            }
                        } else {
                            promise = OfflineDataService.joinOneToManyObjects(object, request, childModel);
                        }

                        if (promise) {
                            promises.push(promise);
                        }
                    }
                }
            }

            return PromiseManagementService.combinePromises(promises).then(
                function(updatedObjects) {
                    if (DefaultVariableService.isArray(updatedObjects)) {
                        if (updatedObjects.length !== 0) {
                            while (updatedObjects.length !== 1) {
                                var a = updatedObjects[0];
                                var b = updatedObjects.pop();

                                updatedObjects[0] = DefaultVariableService.mergeObjects(a, b);
                            }

                            return updatedObjects[0];
                        }
                    }

                    return object;
                }
            ).then(
                function(object) {
                    return OfflineDataService.joinChildObjects(object, request);
                }
            );
        }

        OfflineDataService.joinChildObject = joinChildObject;
        function joinChildObject(request, parentObject, childField) {
            var promise = false;
            if (childField.indexOf('joinData') === -1) {
                var childObject = parentObject[childField];

                if (DefaultVariableService.isObject(childObject) || (DefaultVariableService.isArray(childObject) && childObject.length !== 0)) {
                    var childModel = DefaultVariableService.toUnderScore(
                        childField.toLowerCase()
                    );
                    var lastChar = childModel.substring(childModel.length - 1);
                    if (lastChar !== 's') {
                        childModel = childModel + 's';
                    }

                    var params = RequestManagementService.getParams(request);
                    var paramsLength = params.length;

                    var newParams = [];
                    for (var i = 0; i < paramsLength; i++) {
                        var param = params[i];

                        var indexOfChildModel = param.toLowerCase().indexOf(childModel);

                        var indexOfDot = param.indexOf('.');

                        var indexOfEqual = param.indexOf('=');

                        var foundModel = childModel;
                        var foundModelIndex = indexOfChildModel;
                        if (foundModelIndex === -1) {
                            foundModel = DefaultVariableService.toPascalCase(childModel);
                            foundModelIndex = param.indexOf(foundModel);
                        }

                        if (foundModelIndex !== -1) {
                            if (indexOfDot !== -1 && indexOfDot > foundModelIndex) {
                                param = param.substring(indexOfDot + 1);
                            } else {
                                param = param.substring(foundModelIndex + foundModel.length);
                            }
                        } else if (indexOfEqual === -1) {
                            param = '';
                        }

                        if (param.length !== 0) {
                            newParams.push(param);
                        }
                    }

                    var newRequest = RequestManagementService.getRequest();
                    newRequest = RequestManagementService.setModel(newRequest, childModel);

                    newRequest = RequestManagementService.setParams(newRequest, newParams);

                    promise = OfflineDataService.joinObjects(childObject, newRequest).then(
                        function(updatedChild) {
                            if (updatedChild) {
                                if (childField !== childModel) {
                                    if (DefaultVariableService.isArray(updatedChild) && updatedChild.length !== 0) {
                                        updatedChild = updatedChild[0];
                                    }
                                }

                                var updatedParent = {};
                                updatedParent[childField] = updatedChild;

                                return DefaultVariableService.mergeObjects(
                                    updatedParent,
                                    parentObject
                                );
                            }

                            return parentObject;
                        }
                    );
                }
            } else {
                var parentModel = RequestManagementService.getModel(request);

                promise = OfflineDataService.joinJoinData(parentModel, parentObject);
            }

            return promise;
        }

        OfflineDataService.joinJoinData = joinJoinData;
        function joinJoinData(parentModel, parentObject) {
            var joinData = DefaultVariableService.get(
                parentObject._joinData,
                false
            );

            var promises = [];

            if (joinData) {
                for (var property in joinData) {
                    var promise = false;

                    if (joinData.hasOwnProperty(property)) {
                        var indexOf = property.indexOf('_id');
                        if (indexOf !== -1) {
                            var childModel = property.substring(0, indexOf) + 's';

                            var ids = joinData[property];
                            if (!DefaultVariableService.isArray(ids)) {
                                ids = [ids];
                            }

                            if (parentModel !== childModel) {
                                promise = OfflineDataService.joinAttach(parentObject, childModel, ids);
                            }
                        }
                    }

                    if (promise) {
                        promises.push(promise);
                    }
                }
            }

            return PromiseManagementService.combinePromises(promises).then(
                function(updatedObjects) {
                    if (DefaultVariableService.isArray(updatedObjects)) {
                        if (updatedObjects.length !== 0) {
                            while (updatedObjects.length !== 1) {
                                var a = updatedObjects[0];
                                var b = updatedObjects.pop();

                                updatedObjects[0] = DefaultVariableService.mergeObjects(a, b);
                            }

                            return updatedObjects[0];
                        }
                    }

                    return parentObject;
                }
            );
        }

        OfflineDataService.joinAttach = joinAttach;
        function joinAttach(parentObject, childModel, ids) {
            return OfflineStorageService.getAll(childModel, ids).then(
                function(childObjects) {
                    if (childObjects) {
                        if (!DefaultVariableService.isArray(childObjects)) {
                            childObjects = [
                                childObjects
                            ];
                        }

                        parentObject[childModel] = childObjects;
                    }

                    return parentObject;
                }
            );
        }

        OfflineDataService.joinChildObjects = joinChildObjects;
        function joinChildObjects(parentObject, request) {
            var promises = [];

            for (var childModel in parentObject) {
                if (parentObject.hasOwnProperty(childModel)) {
                    var promise = OfflineDataService.joinChildObject(request, parentObject, childModel);

                    if (promise) {
                        promises.push(promise);
                    }
                }
            }

            return PromiseManagementService.combinePromises(promises).then(
                function(updatedObjects) {
                    if (DefaultVariableService.isArray(updatedObjects)) {
                        if (updatedObjects.length !== 0) {
                            while (updatedObjects.length !== 1) {
                                var a = updatedObjects[0];
                                var b = updatedObjects.pop();

                                updatedObjects[0] = DefaultVariableService.mergeObjects(a, b);
                            }

                            return updatedObjects[0];
                        }
                    }

                    return parentObject;
                }
            );
        }

        OfflineDataService.joinObjects = joinObjects;
        function joinObjects(objects, request) {
            var promises = [];

            if (!DefaultVariableService.isArray(objects)) {
                objects = [
                    objects
                ];
            }

            var objectsLength = objects.length;
            for (var i = 0; i < objectsLength; i++) {
                if (DefaultVariableService.isObject(objects[i])) {
                    var promise = OfflineDataService.joinObject(
                        objects[i],
                        request
                    );

                    promises.push(promise);
                }
            }

            return PromiseManagementService.combinePromises(promises);
        }

        OfflineDataService.orderResults = orderResults;
        function orderResults(objects, request) {
            var order = RequestManagementService.getOrder(request);

            if (order) {
                var value = RequestManagementService.getValueFromParam(order);

                if (value) {
                    value = value.split(',');

                    if (value.length !== 0) {
                        value = value[0];

                        var prefix = value.substring(0, 1);

                        value = value.substring(1);

                        var isAscending = true;
                        if (prefix === '-') {
                            isAscending = false;
                        } else if (prefix !== '+') {
                            value = prefix + value;
                        }

                        objects = DefaultVariableService.sortObjects(
                            objects,
                            value,
                            isAscending
                        );
                    }
                }
            }

            return objects;
        }

        OfflineDataService.paginate = paginate;
        function paginate(objects, request) {
            var count = objects.length;

            var limit = RequestManagementService.getLimit(request);

            var page = RequestManagementService.getPage(request);

            var pageCount = Math.round(count / limit + 0.5);
            if (pageCount === 0) {
                pageCount = 1;
            }

            var results = [];
            for (var i = 0, currentPage = 1; i < count && results.length < limit; i++) {
                var item = objects[i];
                if (page === currentPage) {
                    results.push(item);
                }
            }

            var data = {
                canLoadMore: page < pageCount,
                count:       count,
                status:      200,
                pages:       pageCount
            };

            data[RequestManagementService.getModel(request)] = results;

            return data;
        }

        OfflineDataService.reset = reset;
        function reset() {

        }

        OfflineDataService.init = init;
        function init() {

        }

        OfflineDataService.init();

        return OfflineDataService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineRequestService',
        OfflineRequestService
    );

    OfflineRequestService.$inject = [
        'DefaultVariableService',
        'FileManagementService',
        'OfflineDataService',
        'OfflineStorageService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function OfflineRequestService(
        DefaultVariableService,
        FileManagementService,
        OfflineDataService,
        OfflineStorageService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var OfflineRequestService = this;

        var ACTION_RECOMMENDED = 'recommended';
        
        OfflineRequestService.get = get;
        function get(request) {
            return OfflineStorageService.get(request).then(
                function(items) {
                    return OfflineRequestService.handleResponse(request, items);
                }
            );
        }

        OfflineRequestService.handleResponse = handleResponse;
        function handleResponse(request, objects) {
            var model = RequestManagementService.getModel(request);

            var promise = false;
            if (model === 'settings') {
                promise = PromiseManagementService.generateSuccess(objects);
            } else {
                promise = OfflineDataService.joinObjects(objects, request);
            }

            var id = RequestManagementService.getId(request);
            if (id === 0) {
                if (DefaultVariableService.isArray(objects)) {
                    promise = promise.then(
                        function (objects) {
                            var action = RequestManagementService.getAction(request);
                            if (action === ACTION_RECOMMENDED) {
                                if (objects) {
                                    return OfflineDataService.filterRecommended(objects);
                                }
                            }

                            return objects;
                        }
                    ).then(
                        function (objects) {
                            if (objects) {
                                return OfflineDataService.filterByParams(objects, request);
                            }

                            return objects;
                        }
                    ).then(
                        function (objects) {
                            if (objects) {
                                objects = OfflineDataService.orderResults(objects, request);

                                objects = OfflineDataService.paginate(objects, request);
                            }

                            return objects;
                        }
                    );
                }
            } else {
                promise = promise.then(
                    function(object) {
                        if (DefaultVariableService.isArray(object)) {
                            object = object[0];
                        }

                        if (object) {
                            return FileManagementService.updateFile(model, object);
                        }

                        return object;
                    }
                ).then(
                    function(object) {
                        if (object) {
                            var data = {};
                            data[model] = object;

                            return data;
                        }

                        return false;
                    }
                );
            }

            return promise;
        }

        OfflineRequestService.reset = reset;
        function reset() {
            OfflineRequestService.doesNotHaveInternet = true;

            OfflineRequestService.currentlyHasInternet = true;
        }

        OfflineRequestService.init = init;
        function init() {
            OfflineRequestService.reset();
        }

        OfflineRequestService.init();

        return OfflineRequestService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineStorageService',
        OfflineStorageService
    );

    OfflineStorageService.$inject = [
        'DefaultVariableService',
        'ErrorService',
        'FileManagementService',
        'LocalForageService',
        'LocalStorageService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function OfflineStorageService(
        DefaultVariableService,
        ErrorService,
        FileManagementService,
        LocalForageService,
        LocalStorageService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var OfflineStorageService = this;

        var OFFLINE_LAST_SYNC = 'offlineLastSynced';

        OfflineStorageService.addToStorage = addToStorage;
        function addToStorage(model, objects, merge, breakDown) {
            if (DefaultVariableService.isArray(objects) && model !== 'settings') {
                var promises = [];

                objects.forEach(
                    function(object) {
                        if (DefaultVariableService.isObject(object)) {
                            promises.push(
                                OfflineStorageService.addToStorage(model, object, merge, breakDown)
                            );
                        }
                    }
                );

                return PromiseManagementService.combinePromises(promises);
            } else {
                return OfflineStorageService.addObjectToStorage(model, objects, merge, breakDown);
            }
        }

        OfflineStorageService.addObjectToStorage = addObjectToStorage;
        function addObjectToStorage(model, object, merge, breakDown) {
            var isSpecial = model === 'settings';

            merge = DefaultVariableService.get(
                merge,
                false
            ) && !isSpecial;

            breakDown = DefaultVariableService.get(
                breakDown,
                true
            ) && !isSpecial;

            if (breakDown && DefaultVariableService.isObject(object)) {
                object = OfflineStorageService.breakDownObject(model, object);

                var promises = [];

                for (var itemsModel in object) {
                    if (object.hasOwnProperty(itemsModel)) {
                        var items = object[itemsModel];

                        breakDown = model !== itemsModel;

                        promises.push(
                            OfflineStorageService.addToStorage(itemsModel, items, merge, breakDown)
                        );
                    }
                }

                return PromiseManagementService.combinePromises(promises);
            }

            if (merge) {
                return OfflineStorageService.mergeInStorage(model, object);
            } else {
                return OfflineStorageService.setItem(model, object);
            }
        }

        OfflineStorageService.breakDownObject = breakDownObject;
        function breakDownObject(model, objects) {
            if (!DefaultVariableService.isArray(objects)) {
                objects = [
                    objects
                ];
            }

            var returnVar = {};
            returnVar[model] = [];

            var objectsLength = objects.length;
            for (var i = 0; i < objectsLength; i++) {
                var object = objects[i];

                for (var otherModel in object) {
                    if (object.hasOwnProperty(otherModel) && otherModel.indexOf('joinData') === -1) {
                        var otherObject = object[otherModel];

                        var isArray = DefaultVariableService.isArray(otherObject);
                        var isObject = DefaultVariableService.isObject(otherObject);

                        if (isObject || isArray) {
                            delete object[otherModel];

                            var otherModelLength = otherModel.length;

                            var lastChar = otherModel.substring(
                                otherModelLength - 1
                            );

                            if (lastChar !== 's') {
                                otherModel += 's';
                            }

                            if (isObject) {
                                if (!DefaultVariableService.isDefined(returnVar[otherModel])) {
                                    returnVar[otherModel] = [];
                                }

                                returnVar[otherModel].push(otherObject);
                            } else if (isArray) {
                                var newReturnVar = OfflineStorageService.breakDownObject(otherModel, otherObject);

                                returnVar = DefaultVariableService.mergeObjects(newReturnVar, returnVar);
                            }
                        }
                    }
                }

                returnVar[model].push(object);
            }

            return returnVar;
        }

        OfflineStorageService.buildResponse = buildResponse;
        function buildResponse(response, model, object) {
            if (response) {
                response = {};
                response[model] = object;

                return response;
            }

            return false;
        }

        OfflineStorageService.clearAll = clearAll;
        function clearAll() {
            return LocalForageService.clear();
        }

        OfflineStorageService.create = create;
        function create(request) {
            var object = RequestManagementService.getData(request);
            if (object) {
                var model = RequestManagementService.getModel(request);

                object.id = OfflineStorageService.generateId();

                return LocalForageService.exists(object).then(
                    function(doesExist) {
                        if (doesExist) {
                            ErrorService.addWarning(
                                'Create called for existing item.'
                            );

                            return OfflineStorageService.edit(model, object);
                        } else {
                            return OfflineStorageService.addToStorage(model, object).then(
                                function(response) {
                                    return OfflineStorageService.buildResponse(response, model, object);
                                }
                            );
                        }
                    }
                );
            } else {
                return ErrorService.addError(
                    'Create requires the request to contain data to edit while offline.'
                );
            }
        }

        OfflineStorageService.edit = edit;
        function edit(request) {
            var object = RequestManagementService.getData(request);
            if (object) {
                var model = RequestManagementService.getModel(request);

                var isSettings = model === 'settings';

                if (DefaultVariableService.isObject(object) || isSettings) {
                    if (isSettings) {
                        object = DefaultVariableService.get(
                            object.settings,
                            object
                        );
                    } else {
                        object.editedOffline = true;
                    }

                    return OfflineStorageService.addToStorage(model, object).then(
                        function(response) {
                            return OfflineStorageService.buildResponse(response, model, object);
                        }
                    );
                } else {
                    return ErrorService.addWarning(
                        'Only objects can be edited while offline (' + model + ').'
                    );
                }
            } else {
                return ErrorService.addError(
                    'Edit requests require data in the request while offline.'
                );
            }
        }

        OfflineStorageService.generateId = generateId;
        function generateId() {
            return Math.random().toFixed(12);
        }

        OfflineStorageService.get = get;
        function get(request) {
            var model = RequestManagementService.getModel(request);

            var id = RequestManagementService.getId(request);
            if (id !== 0) {
                return LocalForageService.getItem(model, id).then(
                    function(item) {
                        if (item) {
                            return [
                                item
                            ];
                        }

                        return false;
                    }
                );
            } else {
                return OfflineStorageService.getAll(model);
            }
        }

        OfflineStorageService.getActiveUser = getActiveUser;
        function getActiveUser() {
            var activeUser = LocalStorageService.get('activeUser');

            if (activeUser) {
                activeUser = JSON.parse(
                    activeUser
                );
            }

            return activeUser;
        }

        OfflineStorageService.getAll = getAll;
        function getAll(model, ids) {
            return LocalForageService.getAll(model).then(
                function(items) {
                    if (DefaultVariableService.isArray(ids)) {
                        items = items.filter(
                            function(item) {
                                var itemId = DefaultVariableService.getInteger(
                                    item.id,
                                    0
                                );

                                if (itemId !== 0) {
                                    var idsLength = ids.length;
                                    for (var i = 0; i < idsLength; i++) {
                                        var id = DefaultVariableService.getInteger(
                                            ids[i],
                                            0
                                        );

                                        if (itemId === id) {
                                            return true;
                                        }
                                    }
                                }

                                return false;
                            }
                        );
                    }

                    return items;
                }
            );
        }

        OfflineStorageService.getCount = getCount;
        function getCount(model) {
            return OfflineStorageService.getAll(model).then(
                function(objects) {
                    if (objects) {
                        objects = DefaultVariableService.getArray(
                            objects
                        );

                        var count = objects.length;

                        return {
                            model: model,
                            count: count
                        };
                    }

                    return false;
                }
            );
        }

        OfflineStorageService.getItem = getItem;
        function getItem(model, id) {
            return LocalForageService.getItem(model, id);
        }

        OfflineStorageService.getLastSync = getLastSync;
        function getLastSync() {
            return DefaultVariableService.getInteger(
                LocalStorageService.get(OFFLINE_LAST_SYNC),
                0
            );
        }

        OfflineStorageService.getLength = getLength;
        function getLength() {
            return LocalForageService.length();
        }

        OfflineStorageService.getToken = getToken;
        function getToken() {
            return LocalStorageService.get('token');
        }

        OfflineStorageService.isLoggedIn = isLoggedIn;
        function isLoggedIn() {
            return OfflineStorageService.getToken() !== false;
        }

        OfflineStorageService.mergeInStorage = mergeInStorage;
        function mergeInStorage(model, alpha) {
            var id = DefaultVariableService.getInteger(
                alpha.id,
                0
            );

            return OfflineStorageService.getItem(model, id).then(
                function(beta) {
                    if (beta) {
                        var lastSync = OfflineStorageService.getLastSync();

                        var addedToOffline = DefaultVariableService.getInteger(
                            beta.addedToOffline
                        );

                        var betaOfflineFilePath = DefaultVariableService.getString(
                            beta.file_path
                        );
                        if (betaOfflineFilePath.indexOf('base64') !== -1) {
                            var alphaFilePath = DefaultVariableService.getString(
                                alpha.file_path
                            );

                            var betaFilePath = DefaultVariableService.getString(
                                beta.online_file_path
                            );

                            if (alphaFilePath === betaFilePath) {
                                alpha.file_path = betaOfflineFilePath;
                            }
                        }

                        if (addedToOffline !== lastSync) {
                            return alpha;
                        } else {
                            var alphaJoinData = DefaultVariableService.get(
                                alpha._joinData,
                                false
                            );

                            var betaJoinData = DefaultVariableService.get(
                                beta._joinData,
                                false
                            );

                            if (alphaJoinData && betaJoinData) {
                                alphaJoinData = DefaultVariableService.mergeObjectsDuplicateFields(
                                    alphaJoinData,
                                    betaJoinData
                                );

                                alpha._joinData = alphaJoinData;

                                delete beta._joinData;
                            } else if (betaJoinData) {
                                alpha._joinData = betaJoinData;

                                delete beta._joinData;
                            }

                            return DefaultVariableService.mergeObjects(
                                alpha,
                                beta
                            );
                        }
                    }

                    return alpha;
                }
            ).then(
                function(object) {
                    if (object) {
                        return OfflineStorageService.setItem(model, object);
                    }

                    return false;
                }
            );
        }

        OfflineStorageService.removeAllByID = removeAllByID;
        function removeAllByID(model, ids) {
            var keys = [];

            var idsLength = ids.length;
            for (var i = 0; i < idsLength; i++) {
                var id = ids[i];

                keys.push(
                    LocalForageService.getKey(model, id)
                );
            }

            return LocalForageService.removeAll(keys);
        }

        OfflineStorageService.removeItem = removeItem;
        function removeItem(model, id) {
            id = DefaultVariableService.get(
                id,
                -1
            );

            return LocalForageService.removeItem(model, id);
        }

        OfflineStorageService.resetLastSync = resetLastSync;
        function resetLastSync() {
            var offlineLastSynced = new Date().getTime();
            if (LocalStorageService.exists(OFFLINE_LAST_SYNC)) {
                LocalStorageService.edit(
                    OFFLINE_LAST_SYNC,
                    offlineLastSynced
                );
            } else {
                LocalStorageService.add(
                    OFFLINE_LAST_SYNC,
                    offlineLastSynced
                );
            }

            return offlineLastSynced;
        }

        OfflineStorageService.setItem = setItem;
        function setItem(model, item) {
            if (model !== 'settings') {
                item.addedToOffline = OfflineStorageService.getLastSync();
            }

            return FileManagementService.updateFilePath(
                item
            ).then(
                function(newItem) {
                    if (newItem) {
                        return LocalForageService.setItem(model, newItem);
                    } else {
                        return LocalForageService.setItem(model, item);
                    }
                }
            );
        }

        OfflineStorageService.reset = reset;
        function reset() {

        }

        OfflineStorageService.init = init;
        function init() {
            OfflineStorageService.reset();
        }

        OfflineStorageService.init();

        return OfflineStorageService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'OfflineSyncService',
        OfflineSyncService
    );

    OfflineSyncService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        '$http',
        'PromiseManagementService',
        'OfflineConnectivityService',
        'OfflineStorageService',
        'RequestManagementService'
    ];

    function OfflineSyncService(
        DefaultVariableService,
        EnvironmentService,
        $http,
        PromiseManagementService,
        OfflineConnectivityService,
        OfflineStorageService,
        RequestManagementService
    ) {
        var OfflineSyncService = this;

        var OFFLINE_REQUESTS = 'offlineRequests';

        OfflineSyncService.buildSyncRequest = buildSyncRequest;
        function buildSyncRequest(model, offlineRequest) {
            return OfflineStorageService.getAll(model).then(
                function(items) {
                    var request = {
                        method: 'POST',
                        headers: {
                            'Content-Type': undefined
                        },
                        data: {}
                    };
                    request.data[model] = [];

                    var canCreate = DefaultVariableService.get(
                        offlineRequest.canCreate,
                        false
                    );
                    if (canCreate) {
                        var createdItems = items.filter(
                            function (item) {
                                var id = DefaultVariableService.get(
                                    item.id,
                                    -1
                                );

                                return id >= 0 && id < 1;
                            }
                        );

                        request.data[model] = request.data[model].concat(createdItems);
                    }

                    var canUpdate = DefaultVariableService.get(
                        offlineRequest.canUpdate,
                        false
                    );
                    if (canUpdate) {
                        var editedItems = items.filter(
                            function(item) {
                                var wasEdited = DefaultVariableService.get(
                                    item.editedOffline,
                                    false
                                );

                                var isSettings = model === 'settings';

                                return wasEdited || isSettings;
                            }
                        );

                        request.data[model] = request.data[model].concat(editedItems);
                    }

                    var canPull = DefaultVariableService.get(
                        offlineRequest.canPull,
                        true
                    );
                    request.data.canPull = canPull;

                    return request;
                }
            );
        }

        OfflineSyncService.buildUrl = buildUrl;
        function buildUrl(offlineRequest) {
            var activeUser = OfflineStorageService.getActiveUser();

            var model = RequestManagementService.getModel(offlineRequest);

            var params = RequestManagementService.getParams(
                offlineRequest
            );

            var paramsLength = params.length;

            if (activeUser) {
                for (var i = 0; i < paramsLength; i++) {
                    var lastChar = params[i].slice(-1);
                    if (lastChar === '=') {
                        var modelUnderScoreId = /[a-z|_]*_id(?=)/;

                        var matches = params[i].match(modelUnderScoreId);
                        if (!matches || matches.length === 0) {
                            var modelDotId = /[a-z|A-Z|_]*\.id(?=)/;

                            matches = params[i].match(modelDotId);
                        }
                        if (!matches || matches.length === 0) {
                            var justId = /[/|&]*id(?=)/;

                            matches = params[i].match(justId);
                        }

                        if (matches && matches.length !== 0) {
                            var matchesLength = matches.length;
                            for (var j = 0; j < matchesLength; j++) {
                                var match = matches[j];

                                var referencedModel = false;

                                var indexOf = match.lastIndexOf('.');
                                if (indexOf !== -1) {
                                    referencedModel = match.substring(0, indexOf);

                                    indexOf = referencedModel.lastIndexOf('.');
                                    if (indexOf !== -1) {
                                        referencedModel = referencedModel.substring(indexOf + 1);
                                    }

                                    referencedModel = referencedModel.toLowerCase();
                                }

                                if (match === 'id') {
                                    referencedModel = model;
                                }

                                if (referencedModel) {
                                    match = referencedModel.slice(0, -1) + '_id';
                                }

                                switch(match) {
                                    case 'project_id':
                                        params[i] += activeUser.project_id;
                                        break;
                                    case 'user_type_id':
                                        params[i] += activeUser.user_type_id;
                                        break;
                                    case 'user_id':
                                    case 'creator_id':
                                        params[i] += activeUser.id;
                                        break;
                                }
                            }
                        }
                    }
                }
            }

            offlineRequest = RequestManagementService.setAction(offlineRequest, 'synchronize');

            offlineRequest = RequestManagementService.clearParams(offlineRequest);

            offlineRequest = RequestManagementService.setParams(offlineRequest, params);

            var url = RequestManagementService.buildUrl(offlineRequest);

            url = RequestManagementService.finalizeUrl(url);

            return url;
        }

        OfflineSyncService.cancelSync = cancelSync;
        function cancelSync() {
            OfflineSyncService.modelsSynched = OfflineSyncService.offlineRequestLength;

            OfflineSyncService.isSyncing = false;
        }

        OfflineSyncService.checkSync = checkSync;
        function checkSync() {
            var shouldSync = false;

            if (!OfflineSyncService.isSyncing) {
                if (OfflineStorageService.isLoggedIn()) {
                    if (OfflineConnectivityService.hasInternet() && !OfflineConnectivityService.hadInternet()) {
                        shouldSync = true;
                    } else {
                        var currentTime = new Date().getTime();

                        var lastSync = OfflineSyncService.getLastSync();

                        var requiredDifference = DefaultVariableService.getInteger(
                            EnvironmentService.get('offlineSyncSeconds'),
                            300
                        );
                        requiredDifference = requiredDifference * 1000;

                        if (currentTime - lastSync >= requiredDifference) {
                            shouldSync = true;
                        }
                    }
                }
            }

            if (shouldSync) {
                return OfflineSyncService.sync(0);
            } else {
                return PromiseManagementService.generateSuccess(shouldSync);
            }
        }

        OfflineSyncService.getIsSyncing = getIsSyncing;
        function getIsSyncing() {
            return OfflineSyncService.isSyncing;
        }

        OfflineSyncService.getLastSync = getLastSync;
        function getLastSync() {
            if (OfflineSyncService.lastSync === 0) {
                OfflineSyncService.lastSync = OfflineStorageService.getLastSync();
            }

            return OfflineSyncService.lastSync;
        }

        OfflineSyncService.getOfflineRequests = getOfflineRequests;
        function getOfflineRequests() {
            return OfflineSyncService.offlineRequests;
        }

        OfflineSyncService.getProgress = getProgress;
        function getProgress() {
            var total = OfflineSyncService.offlineRequestLength;
            if (total === 0) {
                return 1;
            }

            return OfflineSyncService.modelsSynched / total;
        }

        OfflineSyncService.getSyncPosition = getSyncPosition;
        function getSyncPosition(i) {
            if (!DefaultVariableService.isDefined(i)) {
                if (!OfflineSyncService.isSyncing) {
                    OfflineSyncService.checkSync();
                }
            } else if (i === 0) {
                if (!OfflineSyncService.isSyncing) {
                    OfflineSyncService.startSync();
                }
            } else if (!OfflineConnectivityService.hasInternet()) {
                OfflineSyncService.cancelSync();
            } else {
                OfflineSyncService.increaseProgress(i);
            }

            i = DefaultVariableService.getInteger(i);

            return i;
        }

        OfflineSyncService.increaseProgress = increaseProgress;
        function increaseProgress(syncProgress) {
            OfflineSyncService.modelsSynched = syncProgress;

            if (OfflineSyncService.modelsSynched === OfflineSyncService.offlineRequestLength) {
                OfflineSyncService.cancelSync();
            }
        }

        OfflineSyncService.makeSyncRequest = makeSyncRequest;
        function makeSyncRequest(offlineRequest) {
            var url = OfflineSyncService.buildUrl(
                offlineRequest
            );

            var model = RequestManagementService.getModel(offlineRequest);

            return OfflineSyncService.buildSyncRequest(model, offlineRequest).then(
                function(request) {
                    if (request) {
                        request.url = url;

                        return $http(request).then(
                            function(response) {
                                return DefaultVariableService.get(
                                    response.data,
                                    false
                                );
                            },
                            function(response) {
                                if (response.status === -1) {
                                    OfflineSyncService.cancelSync();

                                    return false;
                                } else {
                                    return DefaultVariableService.get(
                                        response.data,
                                        false
                                    );
                                }
                            }
                        );
                    }

                    return false;
                }
            );
        }

        OfflineSyncService.startSync = startSync;
        function startSync() {
            OfflineSyncService.isSyncing = true;

            OfflineSyncService.resetLastSync();

            OfflineSyncService.modelsSynched = 0;
        }

        OfflineSyncService.sync = sync;
        function sync(i) {
            i = OfflineSyncService.getSyncPosition(i);

            if (i < OfflineSyncService.offlineRequestLength) {
                var offlineRequest = OfflineSyncService.offlineRequests[i];

                offlineRequest = RequestManagementService.setLimit(offlineRequest, 1000);

                return OfflineSyncService.makeSyncRequest(offlineRequest).then(
                    function(data) {
                        if (data) {
                            var ids = DefaultVariableService.getArray(
                                data.ids
                            );

                            var model = RequestManagementService.getModel(offlineRequest);

                            return OfflineStorageService.removeAllByID(model, ids).then(
                                function() {
                                    if (DefaultVariableService.isDefined(data[model])) {
                                        var objects = data[model];

                                        return OfflineStorageService.addToStorage(
                                            model,
                                            objects,
                                            true
                                        ).then(
                                            function () {
                                                return true;
                                            }
                                        );
                                    }

                                    return true;
                                }
                            ).then(
                                function () {
                                    return OfflineSyncService.sync(++i);
                                }
                            );
                        }

                        return false;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(true);
        }

        OfflineSyncService.reset = reset;
        function reset() {
            OfflineSyncService.isSyncing = false;

            OfflineSyncService.lastSync = 0;

            OfflineSyncService.resetProgress();
        }

        OfflineSyncService.resetLastSync = resetLastSync;
        function resetLastSync() {
            OfflineSyncService.lastSync = OfflineStorageService.resetLastSync();
        }

        OfflineSyncService.resetProgress = resetProgress;
        function resetProgress() {
            OfflineSyncService.offlineRequests = EnvironmentService.get(OFFLINE_REQUESTS);
            if (OfflineSyncService.offlineRequests) {
                OfflineSyncService.offlineRequests = DefaultVariableService.getArray(
                    OfflineSyncService.offlineRequests
                );
            } else {
                OfflineSyncService.offlineRequests = [];
            }

            OfflineSyncService.offlineRequestLength = OfflineSyncService.offlineRequests.length;

            OfflineSyncService.modelsSynched = OfflineSyncService.offlineRequestLength;
        }

        OfflineSyncService.init = init;
        function init() {
            OfflineSyncService.reset();
            OfflineSyncService.checkSync();
        }

        OfflineSyncService.init();

        return OfflineSyncService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').service(
        'IonicOfflineService',
        IonicOfflineService
    );

    IonicOfflineService.$inject = [
        'DefaultVariableService',
        'ErrorService',
        'FileManagementService',
        'MomentService',
        'OfflineService',
        '$q',
        'RequestManagementService'
    ];

    function IonicOfflineService(
        DefaultVariableService,
        ErrorService,
        FileManagementService,
        MomentService,
        OfflineService,
        $q,
        RequestManagementService
    ) {
        var IonicOfflineService = this;

        IonicOfflineService.getLastSynced = getLastSynced;
        function getLastSynced() {
            var milliseconds = OfflineService.getLastSynced();

            var lastSync = new Date(milliseconds);
            return MomentService.toUserFormat(
                lastSync
            );
        }

        IonicOfflineService.getModelCounts = getModelCounts;
        function getModelCounts() {
            var offlineRequests = OfflineService.getOfflineRequests();

            var offlineRequestsLength = offlineRequests.length;

            var promises = [];

            for (var i = 0; i < offlineRequestsLength; i++) {
                var offlineRequest = offlineRequests[i];

                var model = RequestManagementService.getModel(offlineRequest);

                var promise = OfflineService.getCount(
                    model
                );

                promises.push(promise);
            }

            return $q.all(promises);
        }

        IonicOfflineService.getMissingVideos = getMissingVideos;
        function getMissingVideos() {
            return OfflineService.getAll('videos').then(
                function(videos) {
                    if (videos) {
                        var promises = [];

                        var videosLength = videos.length;
                        for (var i = 0; i < videosLength; i++) {
                            var video = videos[i];

                            var promise = FileManagementService.updateFile('videos', video);

                            promises.push(promise);
                        }

                        return $q.all(promises).then(
                            function(response) {
                                var videosMissingFiles = [];

                                var responseLength = response.length;
                                for (var i = 0; i < responseLength; i++) {
                                    var video = response[i];

                                    if (!video || !video.file_path) {
                                        videosMissingFiles.push(video);
                                    }
                                }

                                return videosMissingFiles;
                            }
                        );
                    }

                    return false;
                }
            );
        }

        IonicOfflineService.getProgress = getProgress;
        function getProgress() {
            return OfflineService.getProgress() * 100;
        }

        IonicOfflineService.getVideoExtension = getVideoExtension;
        function getVideoExtension() {
            return OfflineService.getVideoExtension();
        }

        IonicOfflineService.isSyncing = isSyncing;
        function isSyncing() {
            return OfflineService.isSyncing();
        }

        IonicOfflineService.loadSyncStats = loadSyncStats;
        function loadSyncStats() {
            var data = {};

            return IonicOfflineService.getMissingVideos().then(
                function(missingVideos) {
                    if (missingVideos) {
                        data.missingVideos = missingVideos;
                    }

                    return IonicOfflineService.getModelCounts().then(
                        function(counts) {
                            if (counts) {
                                data.counts = counts;
                            }

                            return data;
                        }
                    );
                }
            );
        }

        IonicOfflineService.synchronize = synchronize;
        function synchronize() {
            if (OfflineService.isOnline()) {
                return OfflineService.synchronize(0);
            } else {
                return ErrorService.addError('CANNOT FORCE SYNC WHILE OFFLINE');
            }
        }

        IonicOfflineService.reset = reset;
        function reset() {

        }

        IonicOfflineService.reset();

        return IonicOfflineService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').controller(
        'OfflineController',
        OfflineController
    );

    OfflineController.$inject = [
        'DefaultVariableService',
        'IonicOfflineService',
        '$scope'
    ];

    function OfflineController(
        DefaultVariableService,
        IonicOfflineService,
        $scope
    ) {
        var OfflineController = this;

        $scope.$watch(
            function() {
                return IonicOfflineService.getLastSynced();
            },
            function(lastSynced) {
                OfflineController.lastSynced = lastSynced;
            }
        );

        $scope.$watch(
            function() {
                return IonicOfflineService.isSyncing();
            },
            function(isSyncing, wasSyncing) {
                OfflineController.isSyncing = DefaultVariableService.get(
                    isSyncing,
                    false
                );

                if (!OfflineController.isSyncing && wasSyncing) {
                    OfflineController.loadSyncStats();
                }
            }
        );

        OfflineController.clear = clear;
        function clear() {
            OfflineController.reset();
            IonicOfflineService.reset();
        }

        OfflineController.forceSync = forceSync;
        function forceSync() {
            return IonicOfflineService.synchronize().then(
                function() {
                    OfflineController.loadSyncStats();
                }
            );
        }

        OfflineController.loadSyncStats = loadSyncStats;
        function loadSyncStats() {
            OfflineController.isLoadingStats = true;

            IonicOfflineService.loadSyncStats().then(
                function(stats) {
                    if (stats) {
                        OfflineController.stats = stats;
                    }

                    return stats;
                }
            ).finally(
                function() {
                    OfflineController.isLoadingStats = false;
                }
            );
        }

        OfflineController.reset = reset;
        function reset() {
            OfflineController.isLoadingStats = false;

            OfflineController.isSyncing = false;

            OfflineController.stats = [];

            OfflineController.videoExtension = IonicOfflineService.getVideoExtension();
        }

        OfflineController.init = init;
        function init() {
            OfflineController.reset();
            OfflineController.loadSyncStats();
        }

        OfflineController.init();
    }
})();

(function() {
    'use strict';

    angular.module('ionicOffline').directive('ionicOffline', ionicOffline);

    function ionicOffline() {
        return {
            controller:   'OfflineController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div class="list card animate zoomIn"><a class="item"><div class="item-body"><h2>LAST SYNCED</h2><p>{{ ctrl.lastSynced }}</p></div><button class="button button-block accept-button" data-ng-click="ctrl.forceSync()" data-ng-disabled="ctrl.isSyncing">{{ ctrl.isSyncing ? \'SYNCING\' : \'FORCE SYNC\' }}</button><div data-ng-if="!ctrl.isLoadingStats || !ctrl.isSyncing" class="list list-inset"><div data-ng-if="ctrl.stats.missingVideos" class="item item-text-wrap item-icon-left" data-ng-repeat="video in ctrl.stats.missingVideos"><i class="icon ion-alert-circled"></i> VIDEO NOT FOUND! Missing \'{{ video.title }}\' while search for \'{{ video.id }}.{{ ctrl.videoExtension }}\' or \'{{ video.youtubeId }}.{{ ctrl.videoExtension }}\'.</div><div data-ng-if="ctrl.stats.counts" class="item item-text-wrap item-icon-left" data-ng-repeat="data in ctrl.stats.counts"><i class="icon ion-android-checkmark-circle"></i> {{ data.count }} {{ data.model }} saved for offline use.</div></div><span data-ng-if="ctrl.isLoadingStats || ctrl.isSyncing"><ion-spinner class="centerSpinner"></ion-spinner></span></a></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').controller(
        'OfflineSyncProgressBarController',
        OfflineSyncProgressBarController
    );

    OfflineSyncProgressBarController.$inject = [
        'DefaultVariableService',
        'OfflineService',
        'ngProgressFactory',
        '$scope'
    ];

    function OfflineSyncProgressBarController(
        DefaultVariableService,
        OfflineService,
        ngProgressFactory,
        $scope
    ) {
        var OfflineSyncProgressBarController = this;

        $scope.$watch(
            function() {
                return OfflineService.getProgress() * 100;
            },
            function(progress) {
                OfflineSyncProgressBarController.setProgress(progress);
            }
        );

        OfflineSyncProgressBarController.checkSync = checkSync;
        function checkSync() {
            OfflineService.checkSync();
        }

        OfflineSyncProgressBarController.clear = clear;
        function clear() {
            OfflineSyncProgressBarController.reset();
            OfflineService.reset();
        }

        OfflineSyncProgressBarController.setProgress = setProgress;
        function setProgress(progress) {
            if (!DefaultVariableService.isDefined(
                    OfflineSyncProgressBarController.progressBar
                )) {
                OfflineSyncProgressBarController.progressBar = ngProgressFactory.createInstance();
            }

            OfflineSyncProgressBarController.progress = progress;

            if (progress >= 100) {
                OfflineSyncProgressBarController.progressBar.complete();
            } else {
                OfflineSyncProgressBarController.progressBar.set(
                    OfflineSyncProgressBarController.progress
                );
            }
        }

        OfflineSyncProgressBarController.reset = reset;
        function reset() {
            OfflineSyncProgressBarController.progress = 0;
        }

        OfflineSyncProgressBarController.init = init;
        function init() {
            OfflineSyncProgressBarController.reset();
            OfflineSyncProgressBarController.checkSync();
        }

        OfflineSyncProgressBarController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicOffline').directive(
        'ionicOfflineSyncProgressBar',
        ionicOfflineSyncProgressBar
    );

    function ionicOfflineSyncProgressBar() {
        return {
            controller:   'OfflineSyncProgressBarController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {}
        };
    }
})();